import React from "react"
import AsyncSelectBase from "react-select/async"
import CreatableSelectBase, { CreatableProps } from "react-select/creatable"
import SelectBase, { OptionTypeBase } from "react-select"
import "./Select.scss"
import styles from "./Select.module.scss"
import Spinner from "../Spinner/Spinner"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { AsyncProps } from "react-select/src/Async"
import { Props as SelectProps } from "react-select/src/Select"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faTimes } from "@fortawesome/pro-light-svg-icons"

export interface SelectCustomProps {
    invalid?: boolean
}

const LoadingIndicator = () => <Spinner className="custom-select__spinner" size={15} />
const ClearIndicator = () => (
    <div className={styles.indicator}>
        <FontAwesomeIcon icon={faTimes} className={styles.indicator__icon} />
    </div>
)
const DropdownIndicator = () => (
    <div className={styles.indicator}>
        <FontAwesomeIcon icon={faCaretDown} className={styles.indicator__icon} />
    </div>
)

export const AsyncSelect: React.FC<SelectProps<OptionType, false> & AsyncProps<OptionType> & SelectCustomProps> =
    props => (
        <AsyncSelectBase
            {...props}
            menuShouldScrollIntoView={false}
            classNamePrefix="custom-select"
            components={{
                LoadingIndicator,
                ClearIndicator,
                DropdownIndicator
            }}
            className={cn(props.className, props.invalid && "custom-select_invalid")}
        />
    )

export const Select: React.FC<SelectProps & SelectCustomProps> = props => (
    <SelectBase
        {...props}
        menuShouldScrollIntoView={false}
        classNamePrefix="custom-select"
        className={cn(props.className, props.invalid && "custom-select_invalid")}
        components={{ DropdownIndicator, ClearIndicator }}
    />
)

export interface CreatableSelectProps extends SelectProps {
    hideDropdownIndicator?: boolean
    isValidNewOption?: CreatableProps<OptionTypeBase, boolean>["isValidNewOption"]
}

export const CreatableSelect: React.FC<CreatableSelectProps> = props => (
    <CreatableSelectBase
        {...props}
        classNamePrefix="custom-select"
        className={cn(props.className, props.invalid && "custom-select_invalid")}
        components={{ DropdownIndicator: props.hideDropdownIndicator ? null : DropdownIndicator, ClearIndicator }}
    />
)
