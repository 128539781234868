import { ApiMessage, MessageCase, MessageTextType, SendMessageRequest } from "../../models/Dialogs/message"

export const convertRequestToResponse = (
    message: SendMessageRequest,
    operatorId: string,
    messageId: string
): ApiMessage => {
    return {
        Case: MessageCase.Message,
        Fields: {
            ...message,
            Id: messageId,
            MessageType: 1,
            TextType: MessageTextType.Markdown,
            Direction: 1,
            Sender: { Id: operatorId },
            DialogId: "",
            AddedDate: Date.now(),
            Context: [[]],
            Status: 0
        }
    }
}
