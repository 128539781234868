import React, { useCallback, useEffect, useState } from "react"
import { RouteComponentProps, useParams } from "react-router"
import styles from "./ProjectMenuSwitch.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import loadable from "@loadable/component"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"
import { MenuItem } from "../../models/menu"
import { buildArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import { ArticleAction } from "../../models/article"
import { push } from "connected-react-router"
import OldWorkplaceFrame from "../OldWorkplaceFrame/OldWorkplaceFrame"
import KnowledgeBase from "../../pages/KnowledgeBase/KnowledgeBase"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import { promiseDelay } from "../../utility/common/promiseDelay"
import { selectCurrentMainMenu } from "../../store/menu/selectors"
import { selectMainMenu } from "../../store/menu/thunks"
import { buildMenuItemUrl } from "../../utility/menu/menuUrlHelper"
import { MatchPath } from "../../types/matchPath"
import UserManagement from "../../pages/UserManagement/UserManagement"
import UserPage from "../../pages/User/User"
import { MenusFrame } from "../MenusFrame/MenusFrame"
import { CustomMenusValue } from "../../models/projectSettings"
import { formatIntegrationUrl } from "../../utility/common/formatIntegrationUrl"
import { selectCurrentUser } from "../../store/users/selectors"
import { isWorkplaceIdOld } from "../../utility/project/frameHelper"
import { MassMailing } from "../../pages/MassMailing/MassMailing"
import { useAppSelector } from "../../store/hooks"
import { selectReferences } from "../../store/projects/selectors"

const Loading = <FullscreenLoader hideLogo />
const Dialogs = loadable(() => promiseDelay(import("../../pages/Dialogs/Dialogs"), 200), { fallback: Loading })
const Queues = loadable(() => promiseDelay(import("../../pages/Queues/Queues"), 200), { fallback: Loading })
const ProjectSettings = loadable(() => promiseDelay(import("../../pages/ProjectSettings/ProjectSettings"), 200), {
    fallback: Loading
})

export interface ProjectMenuSwitchProps extends RouteComponentProps<MatchPath> {
    url: string
    defaultArticleId: string | boolean
    defaultMenu?: MenuItem
    onCheckMenuAvailability: (menuId: string) => boolean
    customMenus?: CustomMenusValue
    projectId?: string
}

const ProjectMenuSwitch: React.FC<ProjectMenuSwitchProps> = props => {
    const { defaultArticleId, url, defaultMenu, onCheckMenuAvailability, customMenus, projectId } = props
    const selectedMenu = useSelector(selectCurrentMainMenu)
    const user = useSelector(selectCurrentUser)
    const dispatch = useDispatch()
    const { menuId } = useParams<MatchPath>()
    const [enableOldWorkplace, setEnableOldWorkplace] = useState<boolean>(true)
    const refs = useAppSelector(selectReferences)

    const handleRouteToDefault = useCallback(() => {
        if (typeof defaultArticleId === "string") {
            const routePath = buildArticleActionUrl(
                `${url}/${definedMenuItems.KnowledgeBase.id}`,
                ArticleAction.View,
                defaultArticleId
            )
            dispatch(push(routePath))
        } else if (defaultMenu) {
            const routePath = buildMenuItemUrl(url, defaultMenu.id)
            dispatch(push(routePath))
        }
    }, [defaultArticleId, defaultMenu, dispatch, url])

    useEffect(() => {
        if (!selectedMenu && !menuId) {
            handleRouteToDefault()
        } else if (menuId) {
            if (onCheckMenuAvailability(menuId)) {
                dispatch(selectMainMenu(menuId))
            } else if (refs) {
                dispatch(push(""))
            }
        }
    }, [dispatch, handleRouteToDefault, menuId, onCheckMenuAvailability, selectedMenu, refs])

    const renderMenus = () => {
        switch (menuId) {
            case definedMenuItems.Dialogs.id:
                return <Dialogs />
            case definedMenuItems.Queues.id:
                return <Queues />
            case definedMenuItems.Profile.id:
                return <UserPage />
            case definedMenuItems.Settings.id:
                return <ProjectSettings />
            case definedMenuItems.UserManagement.id:
                return <UserManagement />
            case definedMenuItems.KnowledgeBase.id:
                return <KnowledgeBase />
            case definedMenuItems.MassMailing.id:
                return <MassMailing />
            default:
                return null
        }
    }

    const renderCustomFrame = () => {
        const menu = customMenus?.Values.find(menu => menu.Id === menuId)
        if (menu) {
            return (
                <MenusFrame
                    src={formatIntegrationUrl(menu.Url, menu.Id, undefined, user, projectId ?? "", true)}
                    title={menu.Title}
                    id={menu.Id}
                />
            )
        }
        return null
    }

    useEffect(() => {
        const isEnabled = (menuId && isWorkplaceIdOld(menuId)) || false
        setEnableOldWorkplace(isEnabled)
    }, [menuId])

    return (
        <div className={styles.projectMenuSwitch}>
            <ErrorBoundary hideLogo>
                <OldWorkplaceFrame selectedMenu={selectedMenu} menuId={menuId} isActive={enableOldWorkplace} />
                {renderCustomFrame()}
                {renderMenus()}
            </ErrorBoundary>
        </div>
    )
}

export default ProjectMenuSwitch
