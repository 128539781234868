import { apiInstance } from "../instances/apiInstance"
import { GetOperatorStatusesResponse, OperatorStatusChangeWithReason } from "../../models/operatorStatus"

const operatorStatusController = {
    get: (): Promise<GetOperatorStatusesResponse> =>
        apiInstance.get("operator-status").then(response => response.data),
    update: (request: OperatorStatusChangeWithReason): Promise<void> =>
        apiInstance.post("operator-status", request).then(response => response.data),
    updateWithBeacon: (request: OperatorStatusChangeWithReason): boolean => {
        const blob = new Blob([JSON.stringify(request)], { type: "application/json" })
        return navigator.sendBeacon("/api/v2/operator-status", blob)
    }
}

export default operatorStatusController
