import { NotificationInternalPayload } from "../../models/notification"
import {
    BlockUserRequest,
    CreateProjectFromCopyRequest,
    CreateProjectRequest,
    IUpdateLogoRequest,
    InviteUsersRequest,
    UpdateProjectRequest
} from "../../models/project"
import { handleHttpException, handleHttpExceptionWithoutAction } from "../handleHttpException"
import { errorTranslation } from "../../locales/error"
import { createSystemError } from "../../core/error"
import { selectCurrentUser } from "../users/selectors"
import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import { selectFrameActivate, selectProjectById, selectProjectCopyWizardFormValues } from "./selectors"
import * as constants from "./constants"
import { RootState } from "../rootReducer"
import { saveNotificationError, saveNotificationInfo } from "../notifications/thunks"
import projectsController from "../../api/controllers/projects"
import { actions } from "./slice"
import { selectProjectSuccess } from "./actions"
import { logDebug } from "../../utility/common/logError"
import {
    buildGetReferenceTypesRequest,
    buildGetReferenceValuesRequest,
    extractReferenceTypes
} from "../../utility/project/reference"
import { ProjectSettings } from "../../models/projectSettings"
import { projectSettingsHub } from "../../api/instances/projectSettingsHub"
import { AxiosProgressEvent } from "axios"

export const subscribeOnProjectSettingsUpdate = (projectId: string) => async (dispatch: Dispatch) => {
    try {
        await projectSettingsHub.subscribe(projectId)
    } catch (e) {
        handleHttpExceptionWithoutAction(e, constants.SUBSCRIBE_ON_PROJECT_SETTINGS_UPDATE_FAILED, dispatch)
    }
}

export const unsubscribeOnProjectSettingsUpdate = (projectId: string) => async (dispatch: Dispatch) => {
    try {
        await projectSettingsHub.unsubscribe(projectId)
    } catch (e) {
        handleHttpExceptionWithoutAction(e, constants.UNSUBSCRIBE_ON_PROJECT_SETTINGS_UPDATE_FAILED, dispatch)
    }
}

export const getProjects = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getProjectsProcess())
    try {
        const projects = await projectsController.getProjects()
        dispatch(actions.getProjectsSuccess(projects))
    } catch (e) {
        handleHttpException(e, constants.FETCH_PROJECTS_FAILED_MESSAGE, err => actions.getProjectsFailed(err), dispatch)
    }
}

export const getDefaultProjectSettings = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getDefaultProjectSettingsProcess())
    try {
        const settings = await projectsController.getDefaultProjectSettings()
        dispatch(actions.getDefaultProjectSettingsSuccess(settings))
    } catch (e) {
        handleHttpException(
            e,
            constants.FETCH_DEFAULT_SETTINGS_FAILED_MESSAGE,
            err => actions.getDefaultProjectSettingsFailed(err),
            dispatch
        )
    }
}

export const getProjectCreatingStatus = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getProjectCreatingStatusProcess())

    try {
        const projectId = localStorage.getItem("ProjectStillCreatingId")

        if (!projectId) {
            return
        }

        const isStillCreating = await projectsController.getProjectCreatingStatus(projectId)
        dispatch(actions.getProjectCreatingStatusSuccess(isStillCreating))
    } catch (e) {
        handleHttpException(
            e,
            constants.FETCH_PROJECTS_FAILED_MESSAGE,
            err => actions.getProjectCreatingStatusFailed(err),
            dispatch
        )
    }
}

export const reloadProjects =
    (payload: NotificationInternalPayload): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getProjectsProcess())
        try {
            saveNotificationInfo(dispatch, payload)
            const projects = await projectsController.getProjects()
            dispatch(actions.getProjectsSuccess(projects))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_PROJECTS_FAILED_MESSAGE,
                err => actions.getProjectsFailed(err),
                dispatch
            )
        }
    }

export const selectProject =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(actions.selectProjectProcess())
        try {
            const state = getState()
            const isFrameActivate = selectFrameActivate(state)
            if (isFrameActivate) {
                dispatch(actions.frameForceUnload())
            }
            const project = selectProjectById(state, projectId)
            const currentUser = selectCurrentUser(state)
            if (currentUser === undefined) {
                const error = createSystemError(constants.SELECT_PROJECT_FAILED_MESSAGE, {
                    reason: "Current user not found",
                    reasonKey: errorTranslation.userNotFound
                })
                saveNotificationError(dispatch, error)
                return
            }
            if (project === undefined) {
                const error = createSystemError(constants.SELECT_PROJECT_FAILED_MESSAGE, {
                    reason: `Project ${projectId} not found`,
                    reasonKey: errorTranslation.projectNotFound
                })
                saveNotificationError(dispatch, error)
                return
            }
            dispatch(selectProjectSuccess({ project, user: currentUser }))
            dispatch(actions.frameForceLoad())
        } catch (e) {
            dispatch(actions.frameForceLoad())
            handleHttpException(
                e,
                constants.SELECT_PROJECT_FAILED_MESSAGE,
                err => actions.selectProjectFailed(err),
                dispatch
            )
        }
    }

export const reloadFrame = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    logDebug("Main frame will be reloaded")
    dispatch(actions.frameForceUnload())
    setTimeout(() => {
        dispatch(actions.frameForceLoad())
    }, 100)
}

export const createProject =
    (request: CreateProjectRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.createProjectProcess())
        try {
            dispatch(actions.createProjectProcess)
            const project = await projectsController.create(request)
            dispatch(actions.createProjectSuccess(project))
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_PROJECT_FAILED_MESSAGE,
                err => actions.createProjectFailed(err),
                dispatch
            )
        }
    }

export const inviteUsers =
    (request: InviteUsersRequest, callback: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.inviteUsersProcess())
            const users = await projectsController.invite(request)
            dispatch(actions.inviteUsersSuccess(users))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.INVITE_USERS_FAILED_MESSAGE,
                err => actions.inviteUsersFailed(err),
                dispatch
            )
        }
    }

export function updateProject(request: UpdateProjectRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateProjectProcess())
            const project = await projectsController.update(request)
            dispatch(actions.updateProjectSuccess(project))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_PROJECT_FAILED_MESSAGE,
                err => actions.updateProjectFailed(err),
                dispatch
            )
        }
    }
}

export function updateLogo(request: IUpdateLogoRequest) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateProjectProcess())
            const project = await projectsController.updateLogo(request)
            dispatch(actions.updateProjectSuccess(project))
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_LOGO_FAILED_MESSAGE,
                err => actions.updateProjectFailed(err),
                dispatch
            )
        }
    }
}

export const blockUser =
    (request: BlockUserRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.blockUserProcess())
            await projectsController.block(request)
            dispatch(actions.blockUserSuccess(request.Login))
        } catch (e) {
            handleHttpException(e, constants.BLOCK_USER_FAILED_MESSAGE, err => actions.blockUserFailed(err), dispatch)
        }
    }

export const uploadProject =
    (file: File, updateProgress: (event: AxiosProgressEvent) => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState()
            const wizardFormValues = selectProjectCopyWizardFormValues(state)

            if (wizardFormValues?.ProjectId) {
                localStorage.setItem("ProjectStillCreatingId", wizardFormValues.ProjectId)
            }

            dispatch(actions.uploadProjectProcess())
            const archiveName = await projectsController.upload(file, updateProgress)
            dispatch(actions.uploadProjectSuccess(archiveName))
        } catch (e) {
            handleHttpException(
                e,
                constants.UPLOAD_PROJECT_FAILED_MESSAGE,
                err => actions.uploadProjectFailed(err),
                dispatch
            )
        }
    }

export const createProjectCopy =
    (request: CreateProjectFromCopyRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.createProjectCopyProcess())
        try {
            await projectsController.createFromCopy(request)
            dispatch(actions.incrementalNextCopyWizardStage())
            dispatch(actions.createProjectCopyProjectRequestSuccess())
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_PROJECT_COPY_FAILED_MESSAGE,
                err => actions.createProjectCopyFailed(err),
                dispatch
            )
        }
    }

export const getReferences =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getReferencesProcess())
        try {
            const referenceTypesRequest = buildGetReferenceTypesRequest(projectId)
            const referenceTypesResponse = await projectsController.getReferenceTypes(referenceTypesRequest)
            const types = extractReferenceTypes(referenceTypesResponse.data)

            const referenceValuesRequest = buildGetReferenceValuesRequest(projectId, types)
            const referenceValuesResponse = await projectsController.getReferenceValues(referenceValuesRequest)

            dispatch(actions.getReferencesSuccess(referenceValuesResponse.data))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_REFERENCES_FAILED_MESSAGE,
                err => actions.getReferencesFailed(err),
                dispatch
            )
        }
    }

export const getProjectSettings =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getProjectSettingsProcess())
        try {
            const { Settings } = await projectsController.getSettings(projectId)
            dispatch(actions.getProjectSettingsSuccess(Settings))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_PROJECT_SETTINGS_FAILED_MESSAGE,
                err => actions.getProjectSettingsFailed(err),
                dispatch
            )
        }
    }

export function updateProjectSettings(projectId: string, settings: ProjectSettings, callback?: () => void) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState()
            const isFrameActivated = selectFrameActivate(state)

            dispatch(actions.updateProjectSettingsProcess())
            const { Diff } = await projectsController.updateSettings(projectId, settings)
            dispatch(actions.updateProjectSettingsSuccess(Diff))

            if (Diff.System && "ColorSchema" in Diff.System) {
                isFrameActivated && dispatch(reloadFrame())
            }

            callback && callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_PROJECT_SETTINGS_FAILED_MESSAGE,
                err => actions.updateProjectSettingsFailed(err),
                dispatch
            )
        }
    }
}

export const getBandwidthGroups = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getBandwidthGroupsProcess())
    try {
        const response = await projectsController.getBandwidthGroups()
        const bandwidthRefIsActive = typeof response === "object" && "Groups" in response && "Default" in response

        dispatch(actions.getBandwidthGroupsSuccess(bandwidthRefIsActive ? response : { Groups: {}, Default: "" }))
    } catch (e) {
        handleHttpException(
            e,
            constants.FETCH_BANDWIDTH_GROUPS_FAILED_MESSAGE,
            err => actions.getBandwidthGroupsFailed(err),
            dispatch
        )
    }
}
