import React from "react"
import "./SettingsTable.scss"
import { ColumnInstance, Row, TableInstance } from "react-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-light-svg-icons"
import AddButton from "../AddButton/AddButton"
import SearchInput from "../SearchInput/SearchInput"

interface BtnAddProps {
    text: string
    onClick: () => void
}

export interface SettingsTableProps<T extends object> {
    btnAddProps?: BtnAddProps
    onRowClick?: (v: T) => void
}

interface Props<T extends object> extends SettingsTableProps<T> {
    tableInstance: TableInstance<T>
    children?: React.ReactNode
    hideSearch?: boolean
    rows: Array<Row<T>>
}

function SettingsTable<T extends object>(props: Props<T>) {
    const {
        btnAddProps,
        onRowClick,
        tableInstance: { setGlobalFilter, getTableProps, getTableBodyProps, headerGroups, prepareRow },
        hideSearch,
        rows,
        children
    } = props

    const handleSearchChange = (searchValue: string) => {
        setGlobalFilter(searchValue)
    }

    const getSortIcon = (column: ColumnInstance<T>) => {
        return column.isSorted ? (column.isSortedDesc ? faSortDown : faSortUp) : faSort
    }

    const handleRowClick = (v: T) => {
        if (onRowClick) onRowClick(v)
    }

    return (
        <div className="settings-table">
            {(!hideSearch || btnAddProps) && (
                <div className="settings-table__header">
                    {!hideSearch && <SearchInput onChange={handleSearchChange} />}
                    {btnAddProps && (
                        <AddButton
                            variant="outline-primary"
                            className="settings-table__btn-add"
                            text={btnAddProps.text}
                            onClick={btnAddProps.onClick}
                        />
                    )}
                </div>
            )}
            <div className="settings-table__body">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps({
                                            ...column.getSortByToggleProps({
                                                title: ""
                                            }),
                                            style: {
                                                width: column.width
                                            }
                                        })}
                                    >
                                        {column.render("Header")}
                                        {column.canSort && (
                                            <FontAwesomeIcon
                                                className="settings-table__sort"
                                                size="1x"
                                                icon={getSortIcon(column)}
                                            />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} onClick={() => handleRowClick(row.original)}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {children}
            </div>
        </div>
    )
}

export default SettingsTable
