export interface SurveyDto {
    Id: string
    Title: string
    Fields: SurveyFieldDto[]
    ProjectId: string
    ModifiedDate: number
    ModifiedUserLogin: string
}

export interface SurveyFieldDto {
    Id: string
    Comment: string
    SlotId: string
    IncreasedSize: boolean
    DisplayType: DisplayTypeValue
    ReadOnly: boolean
    IsCopyRestricted: boolean
}

export interface CreateSurveyFieldDto {
    Comment: string
    SlotId: string
    IncreasedSize?: boolean
    DisplayType?: DisplayTypeValue
    ReadOnly?: boolean
    IsCopyRestricted?: boolean
}

export enum DisplayTypeValue {
    Auto = "Auto",
    Link = "Link"
}

export interface GetSurveysRequest {
    PageFrom: number
    PageSize: number
    Search?: string
}

export interface GetSurveysResponse {
    Data: SurveyDto[]
    Total: number
    Filtered: number
}

export interface CreateSurveyRequest {
    Title: string
    Fields: CreateSurveyFieldDto[]
}

export interface CreateSurveyResponse {
    CreatedSurvey: SurveyDto
}

export interface UpdateSurveyRequest {
    Title: string
    Fields: SurveyFieldDto[]
}

export interface UpdateSurveyResponse {
    UpdatedSurvey: SurveyDto
}

export interface DeleteSurveyResponse {
    Success: boolean
}

export interface SurveyValues {
    Id: string
    Title: string
    Fields: SurveyFieldValue[]
}

export interface SurveyFieldValue extends SurveyFieldDto {
    EditedComment?: string
    IsEditing?: boolean
    IsAdding?: boolean
}
