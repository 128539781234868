import React, { useCallback, useContext, useEffect, useState, useRef, RefObject } from "react"
import cn from "classnames"

import styles from "./PageLayout.module.scss"
import SidebarRight from "../SidebarRight/SidebarRight"
import IconButton from "../IconButton/IconButton"
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { selectGetSidebarMenuState } from "../../store/menu/selectors"
import { RootState } from "../../store/rootReducer"
import { testId } from "../../utility/tests/testId"
import { actions } from "../../store/menu/slice"
import useScrollUpButtonVisibility from "../../hooks/useScrollUpButtonVisibility"

export type ScrollUpButtonContextType = {
    isScrollUpButtonVisible: boolean
    scrollableArea: RefObject<HTMLDivElement> | null
}
export const CloseSidebarContext = React.createContext(true)
export const ScrollUpButtonContext = React.createContext<ScrollUpButtonContextType>({
    isScrollUpButtonVisible: false,
    scrollableArea: null
})
export const PageLayoutContentId = "page-layout-content"
const LEFT_SIDEBAR_DEFAULT_WIDTH = 380
export const EXTENDED_SIDEBAR_WIDTH = 670

interface SidebarProps {
    className?: string
    classNameClosed?: string
    title: string
    onClose: () => void
    onCloseClick?: () => void
    settings?: JSX.Element[]
    stepBack?: boolean
    onBack?: () => void
    extended?: boolean
}

const Sidebar: React.FC<SidebarProps> = props => {
    const {
        className,
        classNameClosed,
        title,
        onClose,
        onCloseClick,
        children,
        settings,
        onBack,
        extended = false
    } = props
    const isCollapsed = useContext(CloseSidebarContext)

    return (
        <SidebarRight
            title={title}
            className={cn(styles.pageLayoutSidebar, className)}
            classNameClosed={classNameClosed}
            open={!isCollapsed}
            onClose={onClose}
            onCloseClick={onCloseClick}
            settings={settings}
            onBack={onBack}
            width={extended ? EXTENDED_SIDEBAR_WIDTH : undefined}
        >
            {children}
        </SidebarRight>
    )
}

export interface LeftSidebarProps {
    className?: string
    headerClassName?: string
    title: string
    sidebarActions?: JSX.Element[]
    intermediate?: JSX.Element | null
    width?: number
    isScrollable?: boolean
    isExpandable?: boolean
    isCollapsible?: boolean
    isCollapsed?: boolean
    id?: string
}

const LeftSidebar: React.FC<LeftSidebarProps> = props => {
    const {
        className,
        headerClassName,
        title,
        sidebarActions,
        intermediate,
        width,
        isScrollable,
        children,
        isCollapsible,
        isExpandable,
        isCollapsed = false,
        id = ""
    } = props

    const sidebarStatus = useSelector((store: RootState) => selectGetSidebarMenuState(store, id))
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(
        sidebarStatus ? sidebarStatus.closed : isCollapsed
    )
    const dispatch = useDispatch()

    const handleSidebarCollapse = useCallback(() => {
        setIsSidebarCollapsed(isSidebarCollapsed => !isSidebarCollapsed)
    }, [])

    useEffect(() => {
        id && dispatch(actions.selectMenuSidebarPosition({ id: id, closed: isSidebarCollapsed }))
    }, [dispatch, isSidebarCollapsed, id])

    return (
        <>
            <div
                className={cn(
                    styles.pageLayoutLeftSidebar,
                    isCollapsible && styles.pageLayoutLeftSidebar_collapsible,
                    isCollapsible && isCollapsed && styles.pageLayoutLeftSidebar_hidden,
                    isExpandable && sidebarStatus?.closed && styles.pageLayoutLeftSidebar_closed,
                    className
                )}
                style={{ flexBasis: width && width }}
                data-test-id={testId.leftSidebar}
            >
                <div className={cn(styles.pageLayoutLeftSidebar__header, headerClassName)}>
                    <div className={styles.pageLayoutLeftSidebar__title}>{title}</div>
                    {sidebarActions && <div className={styles.pageLayoutLeftSidebar__actions}>{sidebarActions}</div>}
                </div>
                {intermediate && <div className={styles.pageLayoutLeftSidebar__intermediate}>{intermediate}</div>}
                <div
                    className={cn(
                        styles.pageLayoutLeftSidebar__body,
                        !isScrollable ? styles.pageLayoutLeftSidebar__body_notScrollable : undefined
                    )}
                >
                    {children}
                </div>
            </div>
            {isExpandable && (
                <IconButton
                    variant="outline-light"
                    className={
                        sidebarStatus?.closed
                            ? styles.pageLayoutLeftSidebar__collapse
                            : cn(
                                  styles.pageLayoutLeftSidebar__collapse,
                                  styles.pageLayoutLeftSidebar__collapse_collapsed
                              )
                    }
                    icon={sidebarStatus?.closed ? faAngleRight : faAngleLeft}
                    iconClassName={styles.pageLayoutLeftSidebar__collapse__collapseIcon}
                    onClick={handleSidebarCollapse}
                    testId={testId.collapseSidebar}
                ></IconButton>
            )}
        </>
    )
}

interface ContentProps {
    className?: string
    overlappingSidebar?: boolean
    isCollapsible?: boolean
    isCollapsed?: boolean
}

const Content: React.FC<ContentProps> = props => {
    const { children, className, overlappingSidebar, isCollapsible, isCollapsed } = props
    const isSidebarCollapsed = useContext(CloseSidebarContext)

    const scrollableArea = useRef<HTMLDivElement>(null)
    const isScrollUpButtonVisible = useScrollUpButtonVisibility(scrollableArea)

    return (
        <ScrollUpButtonContext.Provider value={{ isScrollUpButtonVisible, scrollableArea }}>
            <div
                ref={scrollableArea}
                id={PageLayoutContentId}
                className={cn(
                    styles.pageLayoutContent,
                    isCollapsible && isCollapsed && styles.pageLayoutContent_hidden,
                    className,
                    !isSidebarCollapsed && !overlappingSidebar ? styles.pageLayoutContent_full : undefined
                )}
            >
                {children}
            </div>
        </ScrollUpButtonContext.Provider>
    )
}

type IPageLayout<P> = React.FC<P> & {
    Content: typeof Content
    Sidebar: typeof Sidebar
    LeftSidebar: typeof LeftSidebar
}

interface Props {
    className?: string
    isSidebarCollapsed: boolean
}

const PageLayout: IPageLayout<Props> = props => {
    const { className, isSidebarCollapsed, children } = props
    return (
        <CloseSidebarContext.Provider value={isSidebarCollapsed}>
            <div className={cn(styles.pageLayout, className)}>{children}</div>
        </CloseSidebarContext.Provider>
    )
}

PageLayout.Content = Content
PageLayout.Sidebar = Sidebar
PageLayout.LeftSidebar = LeftSidebar

export default PageLayout
