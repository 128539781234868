import login from "./login.json"
import queues from "./queues.json"
import signUp from "./signUp.json"
import wizard from "./wizard.json"
import form from "./form.json"
import error from "./error.json"
import project from "./project.json"
import roles from "./roles.json"
import user from "./user.json"
import common from "./common.json"
import channel from "./channel.json"
import agent from "./agent.json"
import knowledgeBase from "./knowledgeBase.json"
import info from "./info.json"
import scenarioEditor from "./scenario-editor.json"
import notification from "./notification.json"
import password from "./password.json"
import operator from "./operator.json"
import task from "./task.json"
import menu from "./menu.json"
import classifiers from "./classifiers.json"
import slot from "./slot.json"
import stickers from "./stickers.json"
import articleTypes from "./articleTypes.json"
import surveys from "./surveys.json"
import dialogs from "./dialogs.json"
import newsline from "./dialogs.json"
import date from "./date.json"
import massMailing from "./massMailing.json"
import reports from "./reports.json"

export const translationEn = {
    login,
    queues,
    signUp,
    wizard,
    form,
    error,
    project,
    roles,
    user,
    common,
    channel,
    agent,
    knowledgeBase,
    info,
    scenarioEditor,
    notification,
    password,
    operator,
    task,
    menu,
    classifiers,
    slot,
    stickers,
    articleTypes,
    surveys,
    dialogs,
    newsline,
    date,
    massMailing,
    reports
}
